import { Box, TextField, Typography } from '@mui/material';
import axios from 'axios'; // Import axios for making API requests
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation after signup
import StyledButton from './common/Button';
import Footer from './Footer';
import { handleEmailLogin } from './Login';

// Password validation criteria
const PASSWORD_CRITERIA = {
  minLength: 8,
  hasUpperCase: /[A-Z]/,
  hasLowerCase: /[a-z]/,
  hasNumber: /\d/,
  hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/
};

const validatePassword = (password) => {
  const errors = [];
  
  if (password.length < PASSWORD_CRITERIA.minLength) {
    errors.push(`At least ${PASSWORD_CRITERIA.minLength} characters long`);
  }
  if (!PASSWORD_CRITERIA.hasUpperCase.test(password)) {
    errors.push('One uppercase letter');
  }
  if (!PASSWORD_CRITERIA.hasLowerCase.test(password)) {
    errors.push('One lowercase letter');
  }
  if (!PASSWORD_CRITERIA.hasNumber.test(password)) {
    errors.push('One number');
  }
  if (!PASSWORD_CRITERIA.hasSpecialChar.test(password)) {
    errors.push('One special character');
  }
  
  return errors;
};

const SignupScreen = ({ email, onSignupSuccess }) => {
  const navigate = useNavigate();
  const [isFirebaseAuth, setIsFirebaseAuth] = useState(false);
  const [tempEmail, setTempEmail] = useState('');
  const [passwordErrors, setPasswordErrors] = useState([]);

  useEffect(() => {
    // Check if user came from Google auth
    const isFirebase = localStorage.getItem('is_firebase_auth') === 'true';
    const storedEmail = localStorage.getItem('temp_email');
    setIsFirebaseAuth(isFirebase);
    setTempEmail(storedEmail || email);
  }, [email]);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    role: '',
    company: '',
    password: '',
    confirmPassword: '',
  });

  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    role: false,
    company: false,
    password: false,
  });

  const [isPasswordMatch, setIsPasswordMatch] = useState(true);
  const [isFormComplete, setIsFormComplete] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // Validate password when it changes
    if (name === 'password') {
      const validationErrors = validatePassword(value);
      setPasswordErrors(validationErrors);
    }

    validateForm({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = (updatedFormData) => {
    const { firstName, lastName, role, company, password, confirmPassword } = updatedFormData;

    // Update errors
    setErrors({
      firstName: !firstName,
      lastName: !lastName,
      role: !role,
      company: !company,
      password: !isFirebaseAuth && !password,
    });

    const passwordValidationErrors = !isFirebaseAuth ? validatePassword(password) : [];
    const isPasswordValid = isFirebaseAuth || passwordValidationErrors.length === 0;
    
    const isComplete = firstName && lastName && role && company && 
      (isFirebaseAuth || (password && confirmPassword && isPasswordValid));

    setIsPasswordMatch(isFirebaseAuth || password === confirmPassword);
    setIsFormComplete(isComplete && (isFirebaseAuth || (password === confirmPassword && isPasswordValid)));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isFormComplete) {
      try {
        if (isFirebaseAuth) {
          // Handle Google auth user creation
          try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/users/`, {
              first_name: formData.firstName,
              last_name: formData.lastName,
              role: formData.role,
              company: formData.company,
              email: tempEmail,
              is_firebase_auth: true
            });
            var userId = response.data.id;
          } catch (error) {
            if (error.response?.status === 409) {
              // If user already exists, update their info instead
              await axios.put(`${process.env.REACT_APP_API_URL}/users/`, {
                first_name: formData.firstName,
                last_name: formData.lastName,
                role: formData.role,
                company: formData.company,
                email: tempEmail
              });
              userId = error.response.data.detail.user.id;
            } else {
              throw error;
            }
          }
          
          // Get token for firebase auth user
          const params = new URLSearchParams();
          params.append('username', tempEmail);
          params.append('password', 'firebase-auth');
          
          const tokenResponse = await axios.post(`${process.env.REACT_APP_API_URL}/token`, params);
          const token = tokenResponse.data.access_token;

          // Store user information
          localStorage.setItem('userId', userId);
          localStorage.setItem('token', token);
          localStorage.setItem('email', tempEmail);
          localStorage.setItem('displayName', `${formData.firstName} ${formData.lastName}`);
          localStorage.setItem('firstName', formData.firstName);
          localStorage.setItem('lastName', formData.lastName);
          localStorage.setItem('role', formData.role);
          localStorage.setItem('company', formData.company);
          
          // Clean up temporary storage
          localStorage.removeItem('temp_email');
          localStorage.removeItem('is_firebase_auth');

          if (onSignupSuccess) {
            await onSignupSuccess({ 
              id: userId, 
              email: tempEmail, 
              displayName: `${formData.firstName} ${formData.lastName}`,
              firstName: formData.firstName,
              lastName: formData.lastName,
              role: formData.role,
              company: formData.company
            });
          }
        } else {
          // Handle email auth user creation
          await axios.put(`${process.env.REACT_APP_API_URL}/users/`, {
            first_name: formData.firstName,
            last_name: formData.lastName,
            role: formData.role,
            company: formData.company,
            email: email,
            password: formData.password,
          });

          // Store user information before login
          localStorage.setItem('firstName', formData.firstName);
          localStorage.setItem('lastName', formData.lastName);
          localStorage.setItem('role', formData.role);
          localStorage.setItem('company', formData.company);
          localStorage.setItem('displayName', `${formData.firstName} ${formData.lastName}`);
          
          await handleEmailLogin(navigate, email, formData.password, { 
            onSuccess: async (user) => {
              if (onSignupSuccess) {
                await onSignupSuccess({
                  ...user,
                  firstName: formData.firstName,
                  lastName: formData.lastName,
                  role: formData.role,
                  company: formData.company,
                  displayName: `${formData.firstName} ${formData.lastName}`
                });
              }
            }
          });
        }

        navigate('/home');
      } catch (error) {
        console.error('Error creating user:', error.response?.data || error.message);
        alert('There was an error creating your account. Please try again.');
      }
    }
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <Typography variant="h4" mb={2}>
          Complete Your Profile
        </Typography>
        <TextField
          label="First Name"
          name="firstName"
          variant="outlined"
          value={formData.firstName}
          onChange={handleChange}
          error={errors.firstName}
          helperText={errors.firstName ? 'First name is required' : ''}
          sx={{ mb: 2, width: '300px' }}
        />
        <TextField
          label="Last Name"
          name="lastName"
          variant="outlined"
          value={formData.lastName}
          onChange={handleChange}
          error={errors.lastName}
          helperText={errors.lastName ? 'Last name is required' : ''}
          sx={{ mb: 2, width: '300px' }}
        />
        <TextField
          label="Role"
          name="role"
          variant="outlined"
          value={formData.role}
          onChange={handleChange}
          error={errors.role}
          helperText={errors.role ? 'Role is required' : ''}
          sx={{ mb: 2, width: '300px' }}
          required
        />
        <TextField
          label="Company"
          name="company"
          variant="outlined"
          value={formData.company}
          onChange={handleChange}
          error={errors.company}
          helperText={errors.company ? 'Company is required' : ''}
          sx={{ mb: 2, width: '300px' }}
          required
        />
        {!isFirebaseAuth && (
          <>
            <TextField
              label="New Password"
              name="password"
              type="password"
              variant="outlined"
              value={formData.password}
              onChange={handleChange}
              error={errors.password || passwordErrors.length > 0}
              helperText={errors.password ? 'Password is required' : ''}
              sx={{ mb: 1, width: '300px' }}
            />
            {passwordErrors.length > 0 && (
              <Box sx={{ mb: 2, width: '300px' }}>
                <Typography variant="caption" color="error">
                  Password must include:
                </Typography>
                {passwordErrors.map((error, index) => (
                  <Typography key={index} variant="caption" color="error" display="block">
                    • {error}
                  </Typography>
                ))}
              </Box>
            )}
            <TextField
              label="Confirm Password"
              name="confirmPassword"
              type="password"
              variant="outlined"
              value={formData.confirmPassword}
              onChange={handleChange}
              sx={{ mb: 2, width: '300px' }}
            />
          </>
        )}
        {!isPasswordMatch && !isFirebaseAuth && (
          <Typography variant="body2" color="error" sx={{ mb: 2 }}>
            Passwords do not match.
          </Typography>
        )}
        <StyledButton
          variant="contained"
          disabled={!isFormComplete}
          onClick={handleSubmit}
          sx={{ mb: 2, width: '300px' }}
        >
          Complete Sign Up
        </StyledButton>
      </Box>
      <Footer />
    </>
  );
};

export default SignupScreen;
