import { Box, CircularProgress, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Footer from './Footer';

const EmailVerification = ({ onVerifySuccess }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [verificationStatus, setVerificationStatus] = useState('pending'); // 'pending', 'loading', 'success', 'error'
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const verifyEmail = async () => {
      const code = searchParams.get('code');
      
      // If already verifying or no code, don't proceed
      if (verificationStatus === 'loading' || !code) {
        return;
      }

      try {
        setVerificationStatus('loading');
        
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/users/verify-email?verification_token=${code}`
        );

        if (response.data.user && response.data.user.email) {
          setVerificationStatus('success');
          // Wait a bit before redirecting to ensure user sees success message
          setTimeout(() => {
            if (onVerifySuccess) {
              onVerifySuccess(response.data.user.email);
            }
            navigate('/user-create');
          }, 2000);
        }
      } catch (error) {
        setVerificationStatus('error');
        if (error.response?.status === 400 && error.response?.data?.detail === "User is already verified") {
          setErrorMessage('This email has already been verified. Please proceed to login.');
          // Redirect to login after showing the message
          setTimeout(() => {
            navigate('/login');
          }, 3000);
        } else {
          setErrorMessage(error.response?.data?.detail || 'Verification failed. Please try again.');
        }
      }
    };

    // Only verify if status is pending
    if (verificationStatus === 'pending') {
      verifyEmail();
    }
  }, [searchParams, navigate, onVerifySuccess, verificationStatus]);

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
        textAlign="center"
        padding={3}
      >
        {verificationStatus === 'loading' && (
          <>
            <CircularProgress size={60} sx={{ mb: 3 }} />
            <Typography variant="h5">
              Verifying your email...
            </Typography>
          </>
        )}

        {verificationStatus === 'success' && (
          <>
            <Typography variant="h5" color="primary" gutterBottom>
              Email Verified Successfully!
            </Typography>
            <Typography variant="body1">
              Redirecting you to complete your profile...
            </Typography>
          </>
        )}

        {verificationStatus === 'error' && (
          <>
            <Typography variant="h5" color="error" gutterBottom>
              Verification Error
            </Typography>
            <Typography variant="body1" color="error">
              {errorMessage}
            </Typography>
          </>
        )}
      </Box>
      <Footer />
    </>
  );
};

export default EmailVerification;
