import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Snackbar, TextField, Typography } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import axios from 'axios';
import React, { memo, useCallback, useEffect, useState } from 'react';
import ConfettiExplosion from 'react-confetti-explosion';
import { useNavigate } from 'react-router-dom';
import DynamicForm from './DynamicForm';

const DocHelperModal = memo(({ open, onClose, onSubmit, userEmail, docId, user, updateDocActions, onVisibilityChange, docText }) => {
  const [signed, setSigned] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState('config');
  const [isExploding, setIsExploding] = React.useState(false);
  const [explosionCoords, setExplosionCoords] = useState(null);
  const [apiData, setApiData] = useState(null);
  const [readyForSign, setReadyForSign] = useState(false);
  const [userEmailField, setUserEmailField] = useState(null);
  const [userNameField, setUserNameField] = useState(null);
  const [hasSetFields, setHasSetFields] = useState(false);
  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  const [invitePairs, setInvitePairs] = useState([]);
  const [emptyFields, setEmptyFields] = useState([]);
  const [updateAttributes, setUpdateAttributes] = useState({});
  const [editableDisplayName, setEditableDisplayName] = useState(user.displayName);
  const [isSigning, setIsSigning] = useState(false);
  const [chatMessage, setChatMessage] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [isHidden, setIsHidden] = useState(true);
  const [isMounted, setIsMounted] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  // const [highlightedText, setHighlightedText] = useState(null);
  const [infoPopupOpen, setInfoPopupOpen] = useState(false);
  const [currentClause, setCurrentClause] = useState('');

  const navigate = useNavigate();

  // Handle initial mount
  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  // Reset isHidden when open prop changes
  useEffect(() => {
    if (!open) {
      setIsHidden(true);
    }
  }, [open]);

  // Notify parent component of visibility changes
  useEffect(() => {
    onVisibilityChange?.(!isHidden);
  }, [isHidden, onVisibilityChange]);

  const handleButtonCoords = (event) => {
    if (!explosionCoords) {
      const buttonRect = event.currentTarget.getBoundingClientRect();
      const buttonCenterX = buttonRect.left + buttonRect.width / 2;
      const buttonCenterY = buttonRect.top + buttonRect.height / 2;
      setExplosionCoords({ x: buttonCenterX, y: buttonCenterY });
    }
  };

  const handleSignedStatus = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/documents/sign/${docId}?signer_email=${encodeURIComponent(userEmail)}`);
      setSigned(response.data);
    } catch (error) {
      console.error("Error getting signature:", error);
      alert("Error getting signature. Please try again.");
    }
  }, [docId, userEmail]);

  useEffect(() => {
    if (open) {
      handleSignedStatus();
    }
  }, [open, handleSignedStatus]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/documents/attributes/${docId}`);
        setApiData(response.data);

        // Check if fields are populated
        // if (response.data[userNameField] && response.data[userEmailField]) {
        //   setPopulateFields(true);
        //   setIsAlreadyPopulated(true);
        // } else {
        //   setIsAlreadyPopulated(false);
        // }

        // Check if the fields have been set before
        if (!hasSetFields) {
          // Filter for fields that do not contain "CLIENT"
          const filteredFields = Object.keys(response.data).filter(key => !key.includes("CLIENT"));

          // Find the first NAME and EMAIL fields that are not "CLIENT"
          const nameField = filteredFields.find(key => key.includes("- NAME"));
          const emailField = filteredFields.find(key => key.includes("- EMAIL"));

          setUserEmailField(emailField);
          setUserNameField(nameField);

          setHasSetFields(true); // Mark that fields have been set
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [setApiData, docId, hasSetFields, userNameField, userEmailField]); // Add hasSetFields to dependencies


  const handleSignValidation = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/documents/attributes/${docId}`);
      const data = response.data;

      // Check if any NAME and EMAIL fields in apiData match the user's credentials
      const emailFieldDefined = Object.keys(apiData).some(key => key.includes("EMAIL") && apiData[key] === userEmail);

      const restFieldsReady = Object.keys(data)
        .filter(key => !key.includes("SIGNATURE"))
        .every(key => data[key]);

      // Set allFieldsReady to true if email field is defined
      const allFieldsReady = emailFieldDefined && restFieldsReady;

      setReadyForSign(allFieldsReady && !signed); // Set readyForSign to true if all conditions are met
      setSelectedTab('sign');
    } catch (error) {
      console.error("Error validating sign status:", error);
    }
  };




  const handleDocumentChange = async (changedAttributes) => {
    const updatedApiData = {
      ...apiData,
      ...changedAttributes
    };

    // Filter out empty non-SIGNATURE fields
    const emptyFields = Object.keys(updatedApiData)
      .filter(key => !key.includes('- SIGNATURE')) // Exclude SIGNATURE fields
      .filter(key => !updatedApiData[key] || updatedApiData[key].trim() === ''); // Check for empty fields

    if (!(emptyFields.length === 0)) {
      setEmptyFields(emptyFields);
      return;
    }

    const detectedPairs = [];

    // 1. Check for NAME/EMAIL pairs completeness
    for (const key in changedAttributes) {
      // console.log('key: ', key);
      if (key.includes('- NAME')) {
        const emailKey = key.replace('- NAME', '- EMAIL');

        // If both NAME and EMAIL are filled, add them to detectedPairs
        if (changedAttributes[key] && changedAttributes[emailKey] && (key in apiData) && (emailKey in apiData) && (key !== userNameField) && (emailKey !== userEmailField)) {
          detectedPairs.push({
            name: changedAttributes[key],
            email: changedAttributes[emailKey]
          });
        }
      }
    }
    // console.log(detectedPairs);

    // 2. If detectedPairs are found, check other required fields
    if (detectedPairs.length > 0) {

      // Set invite pairs and open modal if all conditions are met
      setInvitePairs(detectedPairs);
      setInviteModalOpen(true);
      setUpdateAttributes(changedAttributes);
    }
  };

  const updateDocument = async (attributes) => {
    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/documents/${docId}`, {
        signatures: { signatures: attributes },
        attributes: {}
      });
      localStorage.setItem('selectedAssetId', response.data);
      navigate(`/home/document/${response.data}`);
      updateDocActions();
    } catch (error) {
      console.error("Error updating document:", error);
      alert("Error updating document. Please try again.");
    }
  };

  const handleInviteConfirm = async () => {
    try {
      await updateDocument(updateAttributes);
      // Trigger invite API call for each pair
      await Promise.all(invitePairs.map(pair =>
        axios.post(`${process.env.REACT_APP_API_URL}/documents/invite/`, { email: pair.email, doc_id: docId }, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        })
      ));
      setInviteModalOpen(false);
      setInvitePairs([]);

    } catch (error) {
      console.error("Error inviting users:", error);
      alert("Error inviting users. Please try again.");
      setInviteModalOpen(false);
    }
  };

  // const handleCheckboxChange = (event) => {
  //   console.log(populateFields, isAlreadyPopulated, readyForSign);
  //   const checked = event.target.checked;
  //   setPopulateFields(checked);

  //   // Update only the first available NAME and EMAIL fields that are not "CLIENT"
  //   setApiData(apiData => ({
  //     ...apiData,
  //     [userNameField]: checked ? user.displayName : "",
  //     [userEmailField]: checked ? userEmail : ""
  //   }));
  // };

  const handleSubmit = async () => {
    function findKeyByValue(obj, value) {
      for (const [key, val] of Object.entries(obj)) {
        if (val === value) {
          return key; // Return the key if the value matches
        }
      }
      return null; // Return null if no matching key is found
    }
    try {
      setIsSigning(true);
      let userEmailField2 = findKeyByValue(apiData, userEmail);

      const signResponse = await axios.post(`${process.env.REACT_APP_API_URL}/documents/sign/${docId}?signer_email=${userEmail}`);
      const signatureKey = `${userEmailField2.replace(" - EMAIL", " - SIGNATURE")}`;

      const response = await axios.put(`${process.env.REACT_APP_API_URL}/documents/${docId}`, {
        signatures: {
          signatures: { [signatureKey]: editableDisplayName }
        },
        attributes: {}
      });
      if (signResponse.status === 200) {
        setSnackbarOpen(true);
        setReadyForSign(false);
        setSigned(true);
      }
      localStorage.setItem('selectedAssetId', response.data);
      setIsSigning(false);
      setIsExploding(true);
      await new Promise((resolve) => setTimeout(resolve, 3000));
      navigate(`/home/document/${response.data}`);
    } catch (error) {
      console.error("Error signing document:", error);
      alert("Error signing document. Please try again.");
    } finally {
      setIsExploding(false);
    }
  };

  const handleShowClause = (clauseInfo) => {
    setCurrentClause(clauseInfo);
    setInfoPopupOpen(true);
  };

  const handleChatSubmit = async (e) => {
    e.preventDefault();
    if (!chatMessage.trim()) return;

    // Add user message to chat history
    setChatHistory(prev => [...prev, { sender: 'user', message: chatMessage }]);
    
    // Clear input and show typing indicator
    setChatMessage('');
    setIsTyping(true);
    
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/chat`, {
        document: docText,
        message: chatMessage,
        chat_history: chatHistory
      });
      
      // Extract the quote between QUOTE_START and QUOTE_END
      let quoteText = null;
      if (response.data.response.includes('QUOTE_START') && response.data.response.includes('QUOTE_END')) {
        quoteText = response.data.response
          .split('QUOTE_START')[1]
          .split('QUOTE_END')[0]
          .trim();
      }
      
      // Add bot response with quote info
      setChatHistory(prev => [...prev, { 
        sender: 'bot', 
        message: response.data.response.split('QUOTE_START')[0].trim(),
        clauseInfo: quoteText 
      }]);
    } catch (error) {
      console.error('Error getting chat response:', error);
      setChatHistory(prev => [...prev, { 
        sender: 'bot', 
        message: 'Sorry, I encountered an error processing your request. Please try again.' 
      }]);
    } finally {
      setIsTyping(false);
    }
  };

  // Don't render anything if not open
  if (!open) return null;

  return (
    <>
      <Box sx={{
        position: 'fixed',
        right: 0,
        top: '50%',
        transform: 'translateY(-50%)',
        display: 'flex',
        height: '100%',
        maxHeight: '800px',
        transition: isMounted ? 'all 0.3s ease-in-out' : 'none', // Only enable transition after mount
        zIndex: 1300,
      }}>
        {/* Collapse Toggle Button */}
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          height: '100%',
          zIndex: 1301,
        }}>
          <IconButton
            onClick={() => setIsHidden(!isHidden)}
            sx={{
              backgroundColor: 'white',
              borderRadius: '12px 0 0 12px',
              height: '64px',  // Increased height
              width: '32px',   // Increased width
              boxShadow: '-2px 0 4px rgba(0,0,0,0.1)',
              '&:hover': {
                backgroundColor: 'rgba(255, 145, 77, 0.1)',
              },
              animation: isHidden ? 'pulse 2s infinite' : 'none',
              '@keyframes pulse': {
                '0%': {
                  boxShadow: '-2px 0 4px rgba(0,0,0,0.1)',
                },
                '50%': {
                  boxShadow: '-2px 0 12px rgba(255, 145, 77, 0.3)',
                },
                '100%': {
                  boxShadow: '-2px 0 4px rgba(0,0,0,0.1)',
                }
              }
            }}
          >
            {isHidden ? (
              <ChevronLeftIcon sx={{ 
                fontSize: '28px',
                color: '#ff914d',
                animation: 'bounce 1s infinite',
                '@keyframes bounce': {
                  '0%, 100%': {
                    transform: 'translateX(0)',
                  },
                  '50%': {
                    transform: 'translateX(-3px)',
                  }
                }
              }} />
            ) : (
              <ChevronRightIcon sx={{ 
                fontSize: '28px',
                color: '#ff914d'
              }} />
            )}
          </IconButton>
        </Box>

        {/* Main Content */}
        <Box 
          class="config-box" 
          sx={{ 
            width: isHidden ? '0px' : '400px',
            opacity: isHidden ? 0 : 1,
            visibility: isHidden && isMounted ? 'hidden' : 'visible', // Only hide after mount
            overflow: 'hidden',
            transition: isMounted ? 'all 0.3s ease-in-out' : 'none', // Only enable transition after mount
            backgroundColor: 'white',
            boxShadow: '-2px 0 8px rgba(0,0,0,0.1)',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            zIndex: 1300,
          }}
        >
          <ButtonGroup 
            variant="contained" 
            sx={{ 
              m: 2,
              width: 'calc(100% - 32px)',
              backgroundColor: 'rgba(0, 0, 0, 0.03)',
              padding: '3px',
              borderRadius: '10px',
              border: '1px solid rgba(0, 0, 0, 0.04)',
              '& .MuiButton-root': {
                flex: 1,
                border: 'none',
                backgroundColor: 'transparent',
                color: 'rgba(0, 0, 0, 0.5)',
                fontSize: '0.95rem',
                textTransform: 'none',
                padding: '6px 16px',
                transition: 'all 0.15s ease-in-out',
                borderRadius: '8px',
                minWidth: 0,
                boxShadow: 'none',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.95)',
                  boxShadow: '0 1px 2px rgba(0,0,0,0.05)'
                }
              }
            }}
          >
            <Button 
              onClick={() => setSelectedTab('config')}
              sx={{
                backgroundColor: selectedTab === 'config' ? 'white !important' : 'transparent',
                color: selectedTab === 'config' ? 'rgba(0, 0, 0, 0.8)' : 'rgba(0, 0, 0, 0.5)',
                fontWeight: selectedTab === 'config' ? 500 : 400,
                boxShadow: selectedTab === 'config' ? '0 1px 2px rgba(0,0,0,0.06)' : 'none',
              }}
            >
              Config
            </Button>
            <Button 
              onClick={() => setSelectedTab('chat')}
              sx={{
                backgroundColor: selectedTab === 'chat' ? 'white !important' : 'transparent',
                color: selectedTab === 'chat' ? 'rgba(0, 0, 0, 0.8)' : 'rgba(0, 0, 0, 0.5)',
                fontWeight: selectedTab === 'chat' ? 500 : 400,
                boxShadow: selectedTab === 'chat' ? '0 1px 2px rgba(0,0,0,0.06)' : 'none',
              }}
            >
              Chat
            </Button>
            <Button 
              onClick={() => handleSignValidation()}
              sx={{
                backgroundColor: selectedTab === 'sign' ? 'white !important' : 'transparent',
                color: selectedTab === 'sign' ? 'rgba(0, 0, 0, 0.8)' : 'rgba(0, 0, 0, 0.5)',
                fontWeight: selectedTab === 'sign' ? 500 : 400,
                boxShadow: selectedTab === 'sign' ? '0 1px 2px rgba(0,0,0,0.06)' : 'none',
              }}
            >
              Sign
            </Button>
          </ButtonGroup>

          <Box sx={{ 
            flex: 1,
            overflowY: 'auto',
            px: 2,
            pb: 2
          }}>
            {selectedTab === 'config' && (
              <>
                <Typography class="config-box--title" variant="h2">
                  Config ⚙️
                </Typography>
                <Divider sx={{ width: "100%", mb: 2 }} />
                <Box class="config-box--content">
                  {apiData ? (
                    <DynamicForm apiData={apiData} onSubmitClick={handleDocumentChange} emptyFields={emptyFields} />
                  ) : (
                    <Typography class="config-box--content">Loading...</Typography>
                  )}
                </Box>
              </>
            )}

            {selectedTab === 'sign' && (
              <>
                <Typography class="config-box--title" variant="h2">
                  Sign 🖋️
                </Typography>
                <Divider sx={{ width: "100%" }} />
                <Box class="config-box--content">
                  {isExploding && (
                    <ConfettiExplosion zIndex={9999} force={0.4} width={800} style={{
                      position: 'fixed',
                      left: explosionCoords.x,
                      zIndex: 9999,
                      pointerEvents: 'none'
                    }}
                    />
                  )}

                  {readyForSign && (
                    <div class="config-box--form">
                      <Box class="form-field config-box--field">
                        <TextField
                          label="Signing As"
                          value={editableDisplayName}
                          onChange={(e) => setEditableDisplayName(e.target.value)}
                          variant="outlined"
                          fullWidth
                        />
                        <Typography variant="body1">
                          Signing as {editableDisplayName} with email {userEmail}
                        </Typography>
                      </Box>
                    </div>

                  )}

                  {isSigning ? (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                        width: '100%'
                      }}
                    >
                      <CircularProgress sx={{ color: '#ff914d' }} />
                    </Box>
                  ) : (
                    <Button
                      onClick={(e) => {
                        handleButtonCoords(e);
                        handleSubmit();
                      }}
                      className="btn btn-orange"
                      disabled={!readyForSign}
                    >
                      Adopt and Sign
                    </Button>
                  )}
                </Box>
              </>
            )}

            {selectedTab === 'chat' && (
              <>
                <Typography class="config-box--title" variant="h2">
                  Chat 💬
                </Typography>
                <Divider sx={{ width: "100%" }} />
                <Box sx={{ 
                  display: 'flex',
                  flexDirection: 'column',
                  height: 'calc(100% - 120px)', // Subtract space for title, divider, and padding
                  position: 'relative'
                }}>
                  {/* Messages Container */}
                  <Box 
                    sx={{ 
                      flex: 1,
                      overflowY: 'auto',
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      mb: 2
                    }}
                    ref={(el) => {
                      if (el) {
                        el.scrollTop = el.scrollHeight;
                      }
                    }}
                  >
                    {chatHistory.map((chat, index) => (
                      <Box 
                        key={index}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: chat.sender === 'user' ? 'flex-end' : 'flex-start',
                          mb: 1
                        }}
                      >
                        <Typography
                          sx={{
                            bgcolor: chat.sender === 'user' ? '#ff914d' : '#f0f0f0',
                            color: chat.sender === 'user' ? 'white' : 'black',
                            p: 1,
                            borderRadius: 1,
                            maxWidth: '70%',
                            wordBreak: 'break-word'
                          }}
                        >
                          {chat.message}
                        </Typography>
                        {chat.sender === 'bot' && chat.clauseInfo && (
                          <Button
                            onClick={() => handleShowClause(chat.clauseInfo)}
                            sx={{
                              mt: 0.5,
                              fontSize: '0.8rem',
                              color: '#ff914d',
                              '&:hover': {
                                backgroundColor: 'rgba(255, 145, 77, 0.1)'
                              }
                            }}
                          >
                            Show Relevant Clause
                          </Button>
                        )}
                      </Box>
                    ))}
                    {isTyping && (
                      <Box 
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          mb: 1
                        }}
                      >
                        <Box
                          sx={{
                            bgcolor: '#f0f0f0',
                            p: 1,
                            borderRadius: 1,
                            display: 'flex',
                            gap: 0.5,
                            alignItems: 'center'
                          }}
                        >
                          <Box
                            sx={{
                              width: 8,
                              height: 8,
                              bgcolor: 'rgba(0, 0, 0, 0.5)',
                              borderRadius: '50%',
                              animation: 'bounce 0.8s infinite',
                              '&:nth-of-type(2)': {
                                animationDelay: '0.2s'
                              },
                              '&:nth-of-type(3)': {
                                animationDelay: '0.4s'
                              },
                              '@keyframes bounce': {
                                '0%, 100%': {
                                  transform: 'translateY(0)'
                                },
                                '50%': {
                                  transform: 'translateY(-4px)'
                                }
                              }
                            }}
                          />
                          <Box
                            sx={{
                              width: 8,
                              height: 8,
                              bgcolor: 'rgba(0, 0, 0, 0.5)',
                              borderRadius: '50%',
                              animation: 'bounce 0.8s infinite',
                              animationDelay: '0.2s'
                            }}
                          />
                          <Box
                            sx={{
                              width: 8,
                              height: 8,
                              bgcolor: 'rgba(0, 0, 0, 0.5)',
                              borderRadius: '50%',
                              animation: 'bounce 0.8s infinite',
                              animationDelay: '0.4s'
                            }}
                          />
                        </Box>
                      </Box>
                    )}
                  </Box>

                  {/* Input Box - Fixed at bottom */}
                  <Box 
                    component="form" 
                    onSubmit={handleChatSubmit}
                    sx={{ 
                      display: 'flex',
                      gap: 1,
                      p: 2,
                      borderTop: '1px solid rgba(0, 0, 0, 0.1)',
                      backgroundColor: 'white',
                      position: 'sticky',
                      bottom: 0,
                      left: 0,
                      right: 0,
                      zIndex: 1
                    }}
                  >
                    <TextField
                      fullWidth
                      value={chatMessage}
                      onChange={(e) => setChatMessage(e.target.value)}
                      placeholder="Type your message..."
                      variant="outlined"
                      size="small"
                    />
                    <Button 
                      type="submit"
                      variant="contained"
                      sx={{ 
                        bgcolor: '#ff914d',
                        '&:hover': {
                          bgcolor: '#e67e3c'
                        },
                        minWidth: '80px'
                      }}
                    >
                      Send
                    </Button>
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>

      <Dialog open={inviteModalOpen} onClose={() => setInviteModalOpen(false)}>
        <DialogTitle>Invite Users to Sign</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to invite the following users to sign?</Typography>
          <ul>
            {invitePairs.map((pair, index) => (
              <li key={index}>
                {pair.name}, {pair.email}
              </li>
            ))}
          </ul>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleInviteConfirm} color="primary">
            Yes
          </Button>
          <Button onClick={() => setInviteModalOpen(false)} color="secondary">
            No
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}  // Set how long the Snackbar will be visible (6000ms = 6 seconds)
        onClose={() => setSnackbarOpen(false)}  // Close the Snackbar when the duration expires
        // anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        fullWidth
      >
        {/* <MuiAlert severity="success" sx={{ width: '100%' }}> */}
        <MuiAlert severity="success">
          Document signed successfully!
        </MuiAlert>
      </Snackbar>

      <Dialog
        open={infoPopupOpen}
        onClose={() => setInfoPopupOpen(false)}
        maxWidth="md"
        PaperProps={{
          sx: {
            borderRadius: 2,
            maxWidth: '600px',
            width: '100%'
          }
        }}
      >
        <DialogTitle sx={{ 
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          pb: 1
        }}>
          Relevant Clause
        </DialogTitle>
        <DialogContent sx={{ mt: 2 }}>
          <Typography
            variant="body1"
            sx={{
              backgroundColor: 'rgba(255, 145, 77, 0.1)',
              p: 2,
              borderRadius: 1,
              fontFamily: 'monospace',
              whiteSpace: 'pre-wrap'
            }}
          >
            {currentClause}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setInfoPopupOpen(false)}
            sx={{ 
              color: '#ff914d',
              '&:hover': {
                backgroundColor: 'rgba(255, 145, 77, 0.1)'
              }
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}, (prevProps, nextProps) => {
  // Custom comparison function to determine if re-render is needed
  return (
    prevProps.open === nextProps.open &&
    prevProps.userEmail === nextProps.userEmail &&
    prevProps.docId === nextProps.docId &&
    prevProps.user === nextProps.user
  );
});

export default DocHelperModal;



