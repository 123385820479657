import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box, List, ListItemButton, ListItemIcon, ListItemText, Typography, useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';
import Logo from '../assets/clausible_svg.svg';

const SidebarContainer = styled(Box)(({ darkMode, isMobile }) => ({
  height: '100vh',
  backgroundColor: darkMode ? '#1e1e1e' : '#ffffff',
  transition: 'width 0.3s ease, transform 0.3s ease, background-color 0.3s ease',
  borderRight: darkMode ? '2px solid rgba(255, 145, 77, 0.1)' : '2px solid rgba(255, 145, 77, 0.2)',
  display: 'flex',
  flexDirection: 'column',
  boxShadow: darkMode ? '2px 0 8px rgba(0, 0, 0, 0.2)' : '2px 0 8px rgba(0, 0, 0, 0.05)',
  ...(isMobile && {
    paddingTop: '64px', // Add space for the close button
    alignItems: 'center',
    width: '100%',
  })
}));

const LogoContainer = styled(Box)(({ isMobile }) => ({
  padding: '16px 24px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderBottom: '1px solid rgba(255, 145, 77, 0.1)',
  width: isMobile ? '100%' : 'auto',
  '& img': {
    height: isMobile ? '100px' : '80px',
    width: 'auto',
  },
}));

const StyledListItemButton = styled(ListItemButton)(({ isMobile }) => ({
  margin: '8px 16px',
  borderRadius: '12px',
  transition: 'all 0.2s ease',
  width: isMobile ? 'calc(100% - 32px)' : 'auto',
  '&:hover': {
    backgroundColor: 'rgba(255, 145, 77, 0.1)',
    transform: 'translateX(4px)',
    '& .MuiListItemIcon-root': {
      color: '#ff914d',
    },
    '& .MuiListItemText-primary': {
      color: '#ff914d',
      fontWeight: 600,
    },
  },
  '&.active': {
    backgroundColor: 'rgba(255, 145, 77, 0.15)',
    '& .MuiListItemIcon-root': {
      color: '#ff914d',
    },
    '& .MuiListItemText-primary': {
      color: '#ff914d',
      fontWeight: 600,
    },
  },
}));

const StyledListItemIcon = styled(ListItemIcon)({
  color: '#666',
  minWidth: '40px',
  transition: 'color 0.2s ease',
});

const StyledListItemText = styled(ListItemText)({
  '& .MuiListItemText-primary': {
    fontSize: '0.95rem',
    fontWeight: 500,
    color: '#444',
    transition: 'all 0.2s ease',
  },
});

const FooterContainer = styled(Box)(({ darkMode }) => ({
  marginTop: 'auto',
  padding: '24px',
  borderTop: darkMode ? '1px solid rgba(255, 145, 77, 0.05)' : '1px solid rgba(255, 145, 77, 0.1)',
  textAlign: 'center',
}));

const Sidebar = ({ onHomeClick, onActionClick, onSettingsClick, open, activeTab, darkMode }) => {
  const isSmallScreen = useMediaQuery('(max-width: 800px)');

  return (
    <SidebarContainer sx={{ minWidth: open ? (isSmallScreen ? '100%' : '250px') : 0 }} darkMode={darkMode} isMobile={isSmallScreen}>
      {open && (
        <>
          <LogoContainer isMobile={isSmallScreen}>
            <img src={Logo} alt="Logo" />
          </LogoContainer>
          
          <Box sx={{ mt: 1, width: '100%' }}>
            <List>
              <StyledListItemButton 
                onClick={onHomeClick} 
                className={activeTab === 'home' ? 'active' : ''}
                isMobile={isSmallScreen}
              >
                <StyledListItemIcon>
                  <HomeIcon />
                </StyledListItemIcon>
                <StyledListItemText primary="Home" />
              </StyledListItemButton>

              <StyledListItemButton 
                onClick={onActionClick}
                className={activeTab === 'actions' ? 'active' : ''}
                isMobile={isSmallScreen}
              >
                <StyledListItemIcon>
                  <DirectionsRunIcon />
                </StyledListItemIcon>
                <StyledListItemText primary="Actions" />
              </StyledListItemButton>

              <StyledListItemButton
                onClick={onSettingsClick}
                className={activeTab === 'settings' ? 'active' : ''}
                isMobile={isSmallScreen}
              >
                <StyledListItemIcon>
                  <SettingsIcon />
                </StyledListItemIcon>
                <StyledListItemText primary="Settings" />
              </StyledListItemButton>
            </List>
          </Box>

          <FooterContainer darkMode={darkMode}>
            <Typography variant="caption" color="textSecondary">
              © {(new Date().getFullYear())} Clausible
            </Typography>
          </FooterContainer>
        </>
      )}
    </SidebarContainer>
  );
};

export default Sidebar;
