import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Chip, IconButton, List, ListItem, Menu, MenuItem, Typography, useTheme } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/system';
import React from 'react';

const SectionHeader = styled(Typography)(({ theme }) => ({
    fontWeight: 600,
    color: theme.palette.mode === 'dark' ? '#fff' : '#333',
    marginBottom: '16px',
    marginTop: '24px',
    paddingBottom: '8px',
    borderBottom: '2px solid #ff914d',
}));

const DocumentBox = styled(ListItem)(({ loading, theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#2d2d2d' : '#fff',
    borderRadius: '12px',
    marginBottom: '12px',
    padding: '16px',
    transition: 'all 0.2s ease-in-out',
    boxShadow: theme.palette.mode === 'dark' ? '0 2px 4px rgba(0,0,0,0.2)' : '0 2px 4px rgba(0,0,0,0.05)',
    cursor: loading ? 'default' : 'pointer',
    '&:hover': {
        transform: loading ? 'none' : 'translateY(-2px)',
        boxShadow: loading ? 
            (theme.palette.mode === 'dark' ? '0 2px 4px rgba(0,0,0,0.2)' : '0 2px 4px rgba(0,0,0,0.05)') : 
            (theme.palette.mode === 'dark' ? '0 4px 8px rgba(0,0,0,0.3)' : '0 4px 8px rgba(0,0,0,0.1)'),
        backgroundColor: loading ? 
            (theme.palette.mode === 'dark' ? '#2d2d2d' : '#fff') : 
            (theme.palette.mode === 'dark' ? '#383838' : '#f8f8f8'),
    },
}));

const DocumentText = styled(Box)(({ theme }) => ({
    flex: 1,
    '& .MuiTypography-root': {
        fontSize: '0.95rem',
        color: theme.palette.mode === 'dark' ? '#fff' : 'inherit',
    },
    '& .MuiTypography-secondary': {
        fontSize: '0.8rem',
        color: theme.palette.mode === 'dark' ? '#b3b3b3' : '#666',
    },
}));

const DocumentActions = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
});

const StyledChip = styled(Chip)(({ status }) => ({
    borderRadius: '8px',
    height: '24px',
    fontSize: '0.75rem',
    backgroundColor: (() => {
        switch (status) {
            case 'template':
                return '#e3f2fd';
            case 'draft':
                return '#fff3e0';
            case 'pending':
                return '#fce4ec';
            case 'signed':
                return '#e8f5e9';
            case 'external':
                return '#f3e5f5';
            default:
                return '#e0e0e0';
        }
    })(),
    color: (() => {
        switch (status) {
            case 'template':
                return '#1976d2';
            case 'draft':
                return '#f57c00';
            case 'pending':
                return '#c2185b';
            case 'signed':
                return '#2e7d32';
            case 'external':
                return '#7b1fa2';
            default:
                return '#616161';
        }
    })(),
    marginRight: '16px',
}));

const HomeScreenDocumentListItem = ({ headerName, statusFilter, documents, handleDocumentClick, handleDownload, handleMenuClick, handleMenuClose, handleDelete, handleCreateDraft, formatDate, anchorEl, menuDocIndex, isMenuOpen }) => {
    const theme = useTheme();
    const filteredDocs = documents.filter(statusFilter);
    
    if (filteredDocs.length === 0) {
        return null;
    }

    return (
        <>
            <SectionHeader variant="h6" theme={theme}>{headerName}</SectionHeader>
            <List>
                {filteredDocs.map((doc, index) => (
                    <DocumentBox 
                        key={doc.id} 
                        onClick={() => handleDocumentClick(doc)}
                        loading={doc.loading}
                        theme={theme}
                    >
                        <DocumentText theme={theme}>
                            <Typography variant="body1" sx={{ fontWeight: 500, marginBottom: '4px' }}>
                                {doc.doc_name}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                {formatDate(doc.uploaded_at)}
                            </Typography>
                        </DocumentText>

                        <DocumentActions>
                            <StyledChip 
                                label={doc.loading ? "" : doc.doc_summary.split(".")[0]}
                                status={doc.status}
                            />
                            {doc.loading ? (
                                <CircularProgress sx={{ color: '#ff914d' }} size={24} />
                            ) : (
                                <CheckCircleIcon sx={{ color: '#4caf50' }} />
                            )}
                            <IconButton 
                                onClick={(event) => handleDownload(event, doc.bucket_asset_name)}
                                sx={{ 
                                    color: theme.palette.mode === 'dark' ? '#fff' : 'inherit',
                                    '&:hover': { 
                                        backgroundColor: 'rgba(255, 145, 77, 0.1)',
                                        color: '#ff914d'
                                    }
                                }}
                            >
                                <CloudDownloadIcon />
                            </IconButton>
                            <IconButton
                                onClick={(event) => handleMenuClick(event, index, doc.id)}
                                sx={{ 
                                    color: theme.palette.mode === 'dark' ? '#fff' : 'inherit',
                                    '&:hover': { 
                                        backgroundColor: 'rgba(255, 145, 77, 0.1)',
                                        color: '#ff914d'
                                    }
                                }}
                            >
                                <MoreVertIcon />
                            </IconButton>
                        </DocumentActions>
                    </DocumentBox>
                ))}
            </List>

            <Menu
                anchorEl={anchorEl}
                open={isMenuOpen && menuDocIndex !== null}
                onClose={handleMenuClose}
                onClick={(event) => event.stopPropagation()}
                PaperProps={{
                    sx: {
                        backgroundColor: theme.palette.mode === 'dark' ? '#2d2d2d' : '#fff',
                        color: theme.palette.mode === 'dark' ? '#fff' : 'inherit',
                    }
                }}
            >
                {statusFilter({ status: 'template' }) && (
                    <MenuItem onClick={handleCreateDraft}>Create Draft</MenuItem>
                )}
                <MenuItem onClick={handleDelete}>Delete</MenuItem>
            </Menu>
        </>
    );
};

export default HomeScreenDocumentListItem;