import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Paper, Snackbar, ThemeProvider, Typography, createTheme, useMediaQuery } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { styled } from '@mui/system';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import sadDogImage from '../assets/sad-dog.webp';
import DocumentViewerScreen from './DocumentViewerScreen';
import Footer from './Footer';
import HomeScreenDocumentListItem from './HomeScreenDocumentList';
import SettingsModal from './SettingsModal';
import Sidebar from './Sidebar';
import ToDoListScreen from './ToDoListScreen';

const StyledPaper = styled(Paper)(({ blurred }) => ({
  filter: blurred ? 'blur(5px)' : 'none',
  display: 'flex',
  width: '100vw',
  height: '100vh',
  borderRadius: '0',
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
  backgroundColor: 'rgba(255, 255, 255, 0.6)',
}));

const TopContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  marginBottom: '16px',
  borderBottom: '2px solid #ff914d',
  alignItems: 'center',
  position: 'relative',
  textOverflow: 'ellipsis',
  color: theme.palette.mode === 'dark' ? '#fff' : 'inherit',
}));

const ContentContainer = styled(Box)({
  flex: '1 1 auto',
  padding: '32px',
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
});

const HomeScreen = ({ blurred, documents, docText, setDocuments, onUploadClick, onLogoutClick, onHomeClick, user, tempAccess }) => {
  const isSmallScreen = useMediaQuery('(max-width: 800px)');
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuDocIndex, setMenuDocIndex] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [deletingDocument, setDeletingDocument] = useState(false);
  const [actions, setActions] = useState([]);
  const [selectedDocId, setSelectedDocId] = useState(localStorage.getItem('selectedDocId') || '');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('home');
  const location = useLocation();
  const [darkMode, setDarkMode] = useState(localStorage.getItem('darkMode') === 'true');
  const [settingsOpen, setSettingsOpen] = useState(false);

  const navigate = useNavigate();

  const theme = createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
      background: {
        default: darkMode ? '#121212' : '#ffffff',
        paper: darkMode ? '#1e1e1e' : '#ffffff',
      },
      text: {
        primary: darkMode ? '#ffffff' : '#000000',
        secondary: darkMode ? '#b3b3b3' : '#666666',
      },
    },
  });

  useEffect(() => {
    if (location.pathname.includes('/actionflow')) {
      setActiveTab('actions');
    } else if (location.pathname.includes('/settings')) {
      setActiveTab('settings');
    } else {
      setActiveTab('home');
    }
  }, [location]);

  const handleHomeClick = () => {
    setActiveTab('home');
    onHomeClick();
  };

  const handleActionClick = () => {
    setActiveTab('actions');
    onActionClick();
  };

  const onActionClick = () => {
    const signedActions = documents
      .filter(doc => doc.status === 'signed' || doc.status === 'external') 
      .reduce((acc, doc) => acc.concat(doc.actions), []);

    setActions(signedActions);
    localStorage.setItem('actions', JSON.stringify(signedActions));
    navigate('/home/actionflow');
  };

  useEffect(() => {
    const savedActions = localStorage.getItem('actions');
    if (savedActions) {
      setActions(JSON.parse(savedActions));
    }
  }, []); 


  const toggleDrawer = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleLogoutModal = useCallback((skipDialog = false) => {
    if (skipDialog === true) {
      onLogoutClick();
    } else {
      setLogoutDialogOpen(true);
    }
  }, [onLogoutClick]);

  // eslint-disable-next-line no-unused-vars
  const handleUpdateDocActions = useCallback((actions) => {
    setActions(actions);
    localStorage.setItem('actions', JSON.stringify(actions));
  }, []);

  const handleConfirmLogout = () => {
    setLogoutDialogOpen(false);
    onLogoutClick();
  };

  const handleCancelLogout = () => {
    setLogoutDialogOpen(false);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return 'Invalid date';
    }
    return `Uploaded ${Math.floor((new Date() - date) / (1000 * 60 * 60 * 24))} days ago`;
  };

  const onDocumentClick = (doc) => {
    setTimeout(() => {
      if (!deletingDocument) {
        setActions(doc.actions);
        localStorage.setItem('selectedAssetId', doc.bucket_asset_name);
        localStorage.setItem('selectedDocId', doc.id);
        setSelectedDocId(doc.id);
        navigate(`/home/document/${doc.bucket_asset_name}`);
      }
    }, 300);
  };

  const handleMenuClick = (event, index, documentId) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setIsMenuOpen(true);
    setMenuDocIndex(index);
    setSelectedDocId(documentId);
  };

  const handleCreateDraft = async () => {
    console.log("Creating draft from template...");
    console.log(selectedDocId);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/documents/draft/${selectedDocId}`, {});
      if (response.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      console.error("Error creating draft from template:", error);
      alert("Failed to create draft from template. Please try again.");
    }
  };

  const handleDownload = async (event, fileName) => {
    event.stopPropagation();
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/generate-download-url`, { file_name: fileName, temp_status: tempAccess }, {
        headers: {
          'Authorization': `Bearer ${user.token}`,
        },
      });
      const downloadUrl = response.data.url;
      window.location.href = downloadUrl;
    } catch (error) {
      console.error("Error generating download URL:", error);
      alert("Failed to generate download URL. Please try again.");
    }
  };

  const handleMenuClose = (event) => {
    if (event) {
      event.stopPropagation();
    }
    setAnchorEl(null);
    setIsMenuOpen(false);
    setMenuDocIndex(null);
    setSelectedDocId(null);
  };

  const handleDelete = (event) => {
    event.stopPropagation();
    setConfirmDeleteDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    setConfirmDeleteDialogOpen(false);
    setIsDeleting(true);
    setDeletingDocument(true);
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/documents/?user_id=${user.id}&doc_id=${selectedDocId}`);
      setDocuments((prevDocs) => prevDocs.filter(doc => doc.id !== selectedDocId));
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error deleting document:", error);
      alert("Failed to delete document. Please try again.");
    } finally {
      setAnchorEl(null);
      setMenuDocIndex(null);
      setSelectedDocId(null);
      setDeletingDocument(false);
      setIsDeleting(false);
    }
  };

  const handleCancelDelete = () => {
    setConfirmDeleteDialogOpen(false);
  };

  const handleDocumentClick = (doc) => {
    if (!isDeleting) {
      onDocumentClick(doc);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleDarkModeChange = (checked) => {
    setDarkMode(checked);
    localStorage.setItem('darkMode', checked);
  };

  const handleSettingsClick = () => {
    setSettingsOpen(true);
  };

  const handleSettingsClose = () => {
    setSettingsOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <StyledPaper blurred={blurred ? 'true' : undefined} sx={{ 
        backgroundColor: darkMode ? 'background.paper' : 'rgba(255, 255, 255, 0.6)',
        color: darkMode ? 'text.primary' : 'inherit',
      }}>
        {/* Show sidebar for both desktop and mobile, but with different styles */}
        {sidebarOpen && !tempAccess && (
          <Box sx={{
            position: isSmallScreen ? 'fixed' : 'relative',
            height: '100%',
            zIndex: 1400,
            backgroundColor: darkMode ? 'background.paper' : '#fff',
            boxShadow: isSmallScreen ? '2px 0 8px rgba(0,0,0,0.15)' : 'none',
            transform: isSmallScreen ? 'none' : undefined,
            transition: 'all 0.3s ease-in-out',
            width: isSmallScreen ? '100%' : 'auto',
            left: 0,
            top: 0,
          }}>
            {isSmallScreen && (
              <IconButton 
                onClick={toggleDrawer}
                sx={{
                  position: 'absolute',
                  right: 16,
                  top: 16,
                  zIndex: 1500,
                  color: darkMode ? '#fff' : '#000',
                  backgroundColor: 'rgba(255, 145, 77, 0.1)',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 145, 77, 0.2)',
                  },
                }}
              >
                <CloseIcon />
              </IconButton>
            )}
            <Sidebar 
              onHomeClick={handleHomeClick}
              onActionClick={handleActionClick}
              onSettingsClick={handleSettingsClick}
              open={sidebarOpen}
              activeTab={activeTab}
              darkMode={darkMode}
            />
          </Box>
        )}
        
        {/* Add overlay for mobile */}
        {isSmallScreen && sidebarOpen && (
          <Box
            onClick={toggleDrawer}
            sx={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 1300,
            }}
          />
        )}
        
        <ContentContainer>
          {!tempAccess ? <TopContainer theme={theme}>
            <Box display="flex" alignItems="center" >
              <IconButton onClick={toggleDrawer} sx={{ color: darkMode ? '#fff' : 'inherit' }}>
                <MenuIcon />
              </IconButton>
              <Typography variant="subtitle1" component="h1" gutterBottom sx={{ color: darkMode ? '#fff' : 'inherit' }}>
                Welcome back, <Typography variant="subtitle1" component="span" sx={{ fontWeight: 'bold', color: darkMode ? '#fff' : 'inherit' }}>{user.displayName}</Typography>.
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Button variant="text" sx={{ color: darkMode ? '#fff' : 'inherit' }} onClick={onUploadClick}>
                Upload Document
              </Button>
              <Button variant="text" sx={{ color: darkMode ? '#fff' : 'inherit' }} onClick={handleLogoutModal}>
                Logout
              </Button>
            </Box>
          </TopContainer> : null}

          <Routes>
            <Route path='/' element={
              documents.length === 0 ? (
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%" sx={{
                  paddingTop: isSmallScreen ? '50px' : '0',  // Add padding on top for small screens
                  overflow: 'hidden'
                }}>
                  <img src={sadDogImage} alt="Sad Dog" style={{ width: '300px', height: 'auto', marginBottom: '16px', marginTop: '50px' }} />
                  <Typography variant="h6" gutterBottom sx={{ color: darkMode ? '#fff' : 'inherit' }}>
                    Oops, seems like you haven't uploaded any contracts yet.
                  </Typography>
                  <Typography variant="body1" sx={{ color: darkMode ? '#fff' : 'inherit' }}>
                    Upload contracts to make this puppy happy!
                  </Typography>
                </Box>
              ) : (
                <>
                  <HomeScreenDocumentListItem headerName="Template" statusFilter={(doc) => doc.status === 'template' || doc.status === null || doc.status === undefined || doc.status === ''} documents={documents} handleDocumentClick={handleDocumentClick} handleDownload={handleDownload} handleMenuClick={handleMenuClick} handleMenuClose={handleMenuClose} handleDelete={handleDelete} formatDate={formatDate} anchorEl={anchorEl} menuDocIndex={menuDocIndex} isMenuOpen={isMenuOpen} handleCreateDraft={handleCreateDraft}/>
                  <HomeScreenDocumentListItem headerName="Draft" statusFilter={(doc) => doc.status === 'draft'} documents={documents} handleDocumentClick={handleDocumentClick} handleDownload={handleDownload} handleMenuClick={handleMenuClick} handleMenuClose={handleMenuClose} handleDelete={handleDelete} formatDate={formatDate} anchorEl={anchorEl} menuDocIndex={menuDocIndex}isMenuOpen={isMenuOpen} handleCreateDraft={handleCreateDraft}/>
                  <HomeScreenDocumentListItem headerName="Pending" statusFilter={(doc) => doc.status === 'pending'} documents={documents} handleDocumentClick={handleDocumentClick} handleDownload={handleDownload} handleMenuClick={handleMenuClick} handleMenuClose={handleMenuClose} handleDelete={handleDelete} formatDate={formatDate} anchorEl={anchorEl} menuDocIndex={menuDocIndex} isMenuOpen={isMenuOpen} handleCreateDraft={handleCreateDraft}/>
                  <HomeScreenDocumentListItem headerName="Signed" statusFilter={(doc) => doc.status === 'signed'} documents={documents} handleDocumentClick={handleDocumentClick} handleDownload={handleDownload} handleMenuClick={handleMenuClick} handleMenuClose={handleMenuClose} handleDelete={handleDelete} formatDate={formatDate} anchorEl={anchorEl} menuDocIndex={menuDocIndex} isMenuOpen={isMenuOpen} handleCreateDraft={handleCreateDraft}/>
                  <HomeScreenDocumentListItem headerName="External" statusFilter={(doc) => doc.status === 'external'} documents={documents} handleDocumentClick={handleDocumentClick} handleDownload={handleDownload} handleMenuClick={handleMenuClick} handleMenuClose={handleMenuClose} handleDelete={handleDelete} formatDate={formatDate} anchorEl={anchorEl} menuDocIndex={menuDocIndex} isMenuOpen={isMenuOpen} handleCreateDraft={handleCreateDraft}/>
                </>
              )} />
            <Route path="/actionflow" element={<ToDoListScreen tasks={actions} />} />
            <Route path="/document/:documentName" element={<DocumentViewerScreen userEmail={user.email} docId={selectedDocId} docText={docText} handleLogout={handleLogoutModal} user={user} tempAccess={tempAccess}/>} />
          </Routes>
        </ContentContainer>
        <Dialog 
          open={logoutDialogOpen} 
          onClose={handleCancelLogout}
          PaperProps={{
            sx: {
              backgroundColor: darkMode ? '#2d2d2d' : '#fff',
              color: darkMode ? '#fff' : 'inherit',
              borderRadius: '16px',
              padding: '8px',
              maxWidth: '400px',
              width: '100%',
              transition: 'transform 0.2s ease-in-out',
              '&:hover': {
                transform: 'scale(1.02)'
              }
            }
          }}
          TransitionProps={{
            timeout: 300
          }}
        >
          <DialogTitle sx={{ 
            textAlign: 'center',
            fontSize: '1.5rem',
            fontWeight: 500,
            pb: 1,
            borderBottom: '1px solid',
            borderColor: darkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'
          }}>
            👋 Confirm Logout
          </DialogTitle>
          <DialogContent sx={{ mt: 2 }}>
            <DialogContentText sx={{ 
              color: darkMode ? '#b3b3b3' : 'rgba(0, 0, 0, 0.7)',
              textAlign: 'center',
              fontSize: '1rem'
            }}>
              Are you sure you want to logout from your account?
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ 
            justifyContent: 'center',
            gap: 1,
            pb: 2 
          }}>
            <Button 
              onClick={handleCancelLogout} 
              variant="outlined"
              sx={{ 
                color: '#ff914d',
                borderColor: '#ff914d',
                borderRadius: '8px',
                px: 3,
                '&:hover': {
                  borderColor: '#ff914d',
                  backgroundColor: 'rgba(255, 145, 77, 0.1)'
                }
              }}
            >
              Cancel
            </Button>
            <Button 
              onClick={handleConfirmLogout} 
              variant="contained"
              sx={{ 
                bgcolor: '#ff914d',
                color: 'white',
                borderRadius: '8px',
                px: 3,
                '&:hover': {
                  bgcolor: '#e67e3c'
                }
              }}
              autoFocus
            >
              Logout
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={confirmDeleteDialogOpen}
          onClose={handleCancelDelete}
          PaperProps={{
            sx: {
              backgroundColor: darkMode ? '#2d2d2d' : '#fff',
              color: darkMode ? '#fff' : 'inherit',
            }
          }}
        >
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ color: darkMode ? '#b3b3b3' : 'inherit' }}>
              Are you sure you want to delete this document? This action cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancelDelete} sx={{ color: '#ff914d' }}>
              Cancel
            </Button>
            <Button onClick={handleConfirmDelete} sx={{ color: '#ff914d' }} autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
          <MuiAlert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
            Document deleted successfully.
          </MuiAlert>
        </Snackbar>
        <SettingsModal
          open={settingsOpen}
          onClose={handleSettingsClose}
          darkMode={darkMode}
          onDarkModeChange={handleDarkModeChange}
        />
      </StyledPaper>
      <Footer />
    </ThemeProvider>
  );
};

export default HomeScreen;
