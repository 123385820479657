import { Box, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import { renderAsync } from 'docx-preview';
import mammoth from 'mammoth';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { updateDocActions } from '../utils/documentActions';
import DocHelperModal from './DocHelperModal';

const StyledMainContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  height: '100vh',
  width: '100%',
  position: 'relative',
  overflow: 'hidden'
});

const StyledDocumentContainer = styled(Box)(({ isModalVisible }) => ({
  flex: 1,
  overflowY: 'auto',
  height: '100vh',
  transition: 'all 0.3s ease-in-out',
  marginRight: isModalVisible ? '400px' : '24px',
  '& .docx-wrapper': {
    minHeight: '100%',
    '& .docx': {
      background: 'white',
      padding: '2rem',
      minHeight: '100%',
      maxWidth: '850px',
      margin: '0 auto',
      boxShadow: '0 0 10px rgba(0,0,0,0.1)'
    }
  }
}));

// const StyledHelperContainer = styled(Box)({
//   flex: 1,
//   borderLeft: '1px solid #ddd',
//   overflowY: 'auto',
//   height: '100%',
// });

const DocxViewer = memo(({ userEmail, docText, docId, handleLogout, user, tempAccess }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [selectedDocId, setSelectedDocId] = useState(docId);
  const containerRef = useRef(null);
  const [documentData, setDocumentData] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [extractedText, setExtractedText] = useState('');
  const renderAttempts = useRef(0);
  const maxRenderAttempts = 3;

  const handleUpdateDocActions = useCallback(async () => {
    try {
      await updateDocActions(docId, extractedText);
    } catch (error) {
      console.error('Error handling back to home:', error);
    }
  }, [docId, extractedText]);

  useEffect(() => {
    const fetchDocument = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem('token');
        const fileName = localStorage.getItem("selectedAssetId");

        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/generate-download-url`, 
          { file_name: fileName, temp_status: tempAccess },
          {
            headers: {
              'Authorization': `Bearer ${token}`,
            }
          }
        );

        if (response.status === 200) {
          setSelectedDocId(response.data.docId);
          
          const docResponse = await fetch(response.data.url);
          if (!docResponse.ok) {
            throw new Error(`HTTP error! status: ${docResponse.status}`);
          }
          
          const arrayBuffer = await docResponse.arrayBuffer();
          setDocumentData(arrayBuffer);

          // Extract text from the document
          const result = await mammoth.extractRawText({ arrayBuffer });
          setExtractedText(result.value);
        }
      } catch (error) {
        console.error('Error fetching document:', error);
        if (error.response?.status === 401) {
          handleLogout(true);
        }
        setError(error.message || 'Failed to load document');
      } finally {
        setLoading(false);
      }
    };

    fetchDocument();
  }, [docId, handleLogout, tempAccess]);

  useEffect(() => {
    const renderDocument = async () => {
      if (!documentData || !containerRef.current) {
        return;
      }
      
      try {
        // Clear any existing content first
        if (containerRef.current) {
          containerRef.current.innerHTML = '';
        }

        // Create wrapper with retry logic
        let wrapper = document.createElement('div');
        wrapper.className = 'docx-wrapper';
        containerRef.current.appendChild(wrapper);

        const renderOptions = {
          className: 'docx',
          inWrapper: true,
          useBase64URL: false, // Changed to false to avoid memory issues
          renderHeaders: true,
          renderFooters: true,
          renderFootnotes: true,
        };

        await renderAsync(documentData, wrapper, null, renderOptions);
        console.log('Document rendered successfully');
        renderAttempts.current = 0; // Reset attempts on success
      } catch (renderError) {
        console.error('Error rendering document:', renderError);
        
        // Implement retry logic
        if (renderAttempts.current < maxRenderAttempts) {
          renderAttempts.current += 1;
          console.log(`Retrying render attempt ${renderAttempts.current} of ${maxRenderAttempts}`);
          setTimeout(() => {
            renderDocument();
          }, 1000); // Wait 1 second before retrying
        } else {
          setError('Failed to render document after multiple attempts');
        }
      }
    };

    // Capture the current ref value
    const currentContainer = containerRef.current;

    // Add a small delay before initial render to ensure DOM is ready
    const timeoutId = setTimeout(() => {
      renderDocument();
    }, 100);

    return () => {
      clearTimeout(timeoutId);
      // Use the captured ref value in cleanup
      if (currentContainer) {
        currentContainer.innerHTML = '';
      }
    };
  }, [documentData]);

  const handleSignatureSubmit = (signatureData, emails) => {
    // Implementation here
  };

  return (
    <StyledMainContainer>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="100%"
        >
          <CircularProgress sx={{ color: '#ff914d' }} />
        </Box>
      ) : error ? (
        <StyledDocumentContainer isModalVisible={isModalVisible}>
          <p>{error}</p>
        </StyledDocumentContainer>
      ) : (
        <>
          <StyledDocumentContainer ref={containerRef} isModalVisible={isModalVisible} />
          <DocHelperModal
            open={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            onSubmit={handleSignatureSubmit}
            userEmail={userEmail}
            docId={selectedDocId}
            user={user}
            updateDocActions={handleUpdateDocActions}
            onVisibilityChange={setIsModalVisible}
            docText={extractedText}
          />
        </>
      )}
    </StyledMainContainer>
  );
});

export default DocxViewer;
