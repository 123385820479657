import { Box, Button, Container, Link, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import mainImage from '../assets/2181012-200.png';
import logoImage from '../assets/header_trimmed.jpeg';
import gcloudLogo from '../assets/vendors/gcloud.png';
import openAILogo from '../assets/vendors/openai.png';
import Footer from './Footer';

const Landing = ({setLoading}) => {
  const navigate = useNavigate();

  const handleNavigateToLogin = () => {
    setLoading(true);
    navigate('/login');
    setLoading(false);
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        minHeight="100vh"
        width="100vw"
        overflow="auto"
        sx={{ backgroundColor: '#fafafa' }}
      >
        {/* Header Section - keeping the same structure but adding shadow */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          position="sticky"
          width="100%"
          p={1}
          bgcolor="white"
          sx={{ boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}
        >
          <Box display="flex" alignItems="center">
            <img
              src={logoImage}
              alt="Logo"
              style={{ maxWidth: '186px', maxHeight: '180px', marginLeft: '20px' }}
            />
            <Button
              variant="text"
              component={Link}
              href="https://clausible.notion.site/About-Us-94b631715b034cc3801db1e6719920ad?pvs=4"
              target="_blank"
              rel="noopener"
              sx={{
                ml: 2,
                color: '#000',
                textTransform: 'none',
                '&:hover': {
                  color: '#888',
                  backgroundColor: 'transparent',
                },
              }}
            >
              ABOUT US
            </Button>
          </Box>
          <Button
            variant="text"
            onClick={handleNavigateToLogin}
            sx={{
              color: '#000',
              fontWeight: 'bold',
              '&:hover': {
                color: '#888',
                backgroundColor: 'transparent',
              },
              marginRight: '20px',
            }}
          >
            Login
          </Button>
        </Box>

        {/* Hero Section - Enhanced */}
        <Box
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }}
          justifyContent="center"
          alignItems="center"
          flexGrow={1}
          sx={{
            background: 'linear-gradient(135deg, #ff914d15 0%, #4DBBFF15 100%)',
            py: { xs: 4, md: 8 },
            px: { xs: 2, md: 6 },
          }}
        >
          <Container maxWidth="lg">
            <Box
              display="flex"
              flexDirection={{ xs: 'column', md: 'row' }}
              alignItems="center"
              justifyContent="space-between"
              gap={4}
            >
              <Box flex={1}>
                <Typography 
                  variant="h1" 
                  sx={{ 
                    fontWeight: 800,
                    fontSize: { xs: '2.5rem', md: '3.5rem' },
                    color: '#333',
                    mb: 3,
                    background: 'linear-gradient(45deg, #ff914d, #4DBBFF)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                  }}
                >
                  Contracts, Clauses, Crystal Clear.
                </Typography>
                <Typography 
                  variant="h5" 
                  sx={{ 
                    color: '#555',
                    mb: 2,
                    lineHeight: 1.5 
                  }}
                >
                  Legal AI for small businesses that just works.
                </Typography>
                <Typography 
                  variant="body1" 
                  sx={{ 
                    color: '#666',
                    mb: 4,
                    fontSize: '1.1rem'
                  }}
                >
                  Create, sign, and manage contracts in minutes. No legal jargon, no complexity.
                </Typography>
                <Button
                  variant="contained"
                  onClick={handleNavigateToLogin}
                  sx={{
                    backgroundColor: '#ff914d',
                    padding: '12px 30px',
                    fontSize: '1.1rem',
                    textTransform: 'none',
                    borderRadius: '8px',
                    boxShadow: '0 4px 6px rgba(255, 145, 77, 0.2)',
                    '&:hover': {
                      backgroundColor: '#ff8033',
                      transform: 'translateY(-2px)',
                      boxShadow: '0 6px 8px rgba(255, 145, 77, 0.3)',
                    },
                    transition: 'all 0.3s ease',
                  }}
                >
                  Get Started
                </Button>
              </Box>
              <Box
                sx={{
                  flex: 1,
                  display: 'flex',
                  justifyContent: 'center',
                  transform: { xs: 'scale(0.8)', md: 'scale(1)' },
                }}
              >
                <img
                  src={mainImage}
                  alt="Main Img"
                  style={{
                    maxWidth: '350px',
                    maxHeight: '350px',
                    filter: 'drop-shadow(0 10px 15px rgba(0,0,0,0.1))',
                    animation: 'float 3s ease-in-out infinite',
                  }}
                />
              </Box>
            </Box>
          </Container>
        </Box>

        {/* Partners Section - Enhanced */}
        <Box
          sx={{
            backgroundColor: '#fff',
            py: 3,
            borderTop: '1px solid #eee',
            borderBottom: '1px solid #eee',
          }}
        >
          <Container maxWidth="lg">
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              gap={4}
              flexWrap="wrap"
            >
              <Typography 
                variant="subtitle2" 
                sx={{ 
                  color: '#71717a',
                  letterSpacing: '0.5px',
                  textTransform: 'uppercase',
                  fontSize: '0.75rem'
                }}
              >
                Powered by
              </Typography>
              <img
                src={openAILogo}
                alt="OpenAI"
                style={{ 
                  maxWidth: '120px',
                  filter: 'grayscale(100%) contrast(0.5)',
                  opacity: 0.7,
                  transition: 'opacity 0.3s ease'
                }}
              />
              <img
                src={gcloudLogo}
                alt="Google Cloud"
                style={{ 
                  maxWidth: '160px',
                  filter: 'grayscale(100%) contrast(0.5)',
                  opacity: 0.7,
                  transition: 'opacity 0.3s ease'
                }}
              />
            </Box>
          </Container>
        </Box>

        {/* Why Clausible Section */}
        <Box
          sx={{
            backgroundColor: '#fafafa',
            py: 6,
            borderTop: '1px solid #eee',
          }}
        >
          <Container maxWidth="lg">
            <Typography 
              variant="h2" 
              sx={{ 
                fontWeight: 700,
                fontSize: { xs: '2rem', md: '2.5rem' },
                color: '#333',
                mb: 4,
                textAlign: 'center'
              }}
            >
              Why Clausible?
            </Typography>
            <Box
              display="flex"
              flexDirection={{ xs: 'column', md: 'row' }}
              justifyContent="space-between"
              gap={4}
            >
              {/* Save Card */}
              <Box flex={1} sx={{ textAlign: 'center', p: 3 }}>
                <Box
                  sx={{
                    backgroundColor: '#4D47FF15',
                    borderRadius: '12px',
                    p: 2,
                    mb: 2,
                    display: 'inline-flex',
                  }}
                >
                  <Typography variant="h4" sx={{ color: '#4D47FF' }}>💰</Typography>
                </Box>
                <Typography variant="h5" sx={{ mb: 2, fontWeight: 600, color: '#333' }}>
                  Save Time & Money
                </Typography>
                <Typography variant="body1" sx={{ color: '#666' }}>
                  Generate and review contracts in minutes, not hours. No expensive lawyer fees.
                </Typography>
              </Box>

              {/* Speed Card */}
              <Box flex={1} sx={{ textAlign: 'center', p: 3 }}>
                <Box
                  sx={{
                    backgroundColor: '#ff914d15',
                    borderRadius: '12px',
                    p: 2,
                    mb: 2,
                    display: 'inline-flex',
                  }}
                >
                  <Typography variant="h4" sx={{ color: '#ff914d' }}>⚡</Typography>
                </Box>
                <Typography variant="h5" sx={{ mb: 2, fontWeight: 600, color: '#333' }}>
                  Smart Features
                </Typography>
                <Typography variant="body1" sx={{ color: '#666' }}>
                  Auto-generated to-do lists and notifications for important deadlines.
                </Typography>
              </Box>

              {/* Support Card */}
              <Box flex={1} sx={{ textAlign: 'center', p: 3 }}>
                <Box
                  sx={{
                    backgroundColor: '#4DBBFF15',
                    borderRadius: '12px',
                    p: 2,
                    mb: 2,
                    display: 'inline-flex',
                  }}
                >
                  <Typography variant="h4" sx={{ color: '#4DBBFF' }}>🛡️</Typography>
                </Box>
                <Typography variant="h5" sx={{ mb: 2, fontWeight: 600, color: '#333' }}>
                  Peace of Mind
                </Typography>
                <Typography variant="body1" sx={{ color: '#666' }}>
                  AI-powered contract review to catch red flags and protect your interests.
                </Typography>
              </Box>
            </Box>
          </Container>
        </Box>
        <Footer />
      </Box>
      <style>
        {`
          @keyframes float {
            0% { transform: translateY(0px); }
            50% { transform: translateY(-10px); }
            100% { transform: translateY(0px); }
          }
        `}
      </style>
    </>
  );
};

export default Landing;
