import DarkModeIcon from '@mui/icons-material/DarkMode';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Switch, Typography } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: '12px',
    padding: '16px',
    minWidth: '400px',
  },
}));

const SettingItem = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '16px 0',
  borderBottom: '1px solid rgba(255, 145, 77, 0.1)',
});

const SettingsModal = ({ open, onClose, darkMode, onDarkModeChange }) => {
  return (
    <StyledDialog open={open} onClose={onClose}>
      <DialogTitle>Settings</DialogTitle>
      <DialogContent>
        <SettingItem>
          <Box display="flex" alignItems="center">
            <DarkModeIcon sx={{ marginRight: 2, color: '#ff914d' }} />
            <Typography>Dark Mode</Typography>
          </Box>
          <FormControlLabel
            control={
              <Switch
                checked={darkMode}
                onChange={(e) => onDarkModeChange(e.target.checked)}
                sx={{
                  '& .MuiSwitch-switchBase.Mui-checked': {
                    color: '#ff914d',
                  },
                  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                    backgroundColor: '#ff914d',
                  },
                }}
              />
            }
            label=""
          />
        </SettingItem>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} sx={{ color: '#ff914d' }}>
          Close
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default SettingsModal; 