import axios from 'axios';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { jwtDecode } from 'jwt-decode';

const storeUserInDatabase = async (user, isFirebaseAuth) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/users/`, { first_name: user.displayName, last_name: "", email: user.email, is_firebase_auth: isFirebaseAuth });
    return response.data.id;
  } catch (error) {
    if (error.response && error.response.status === 409) {
      return error.response.data.detail.user.id;
    } else {
      console.error("Error storing user in database:", error.message);
      alert("Failed to store user information. Please try again.");
      return null;
    }
  }
};

const handleGoogleLogin = async (auth, { onSuccess }) => {
  const provider = new GoogleAuthProvider();
  try {
    const result = await signInWithPopup(auth, provider);
    
    try {
      // Try to get user info - if 409, user exists
      await axios.post(`${process.env.REACT_APP_API_URL}/users/`, {
        first_name: result.user.displayName || "",
        last_name: "",
        email: result.user.email,
        is_firebase_auth: true
      });

      // If we get here, user doesn't exist yet - redirect to create
      localStorage.setItem('temp_email', result.user.email);
      localStorage.setItem('is_firebase_auth', 'true');
      localStorage.setItem('idToken', result._tokenResponse.idToken);
      window.location.href = '/user-create';
      
    } catch (error) {
      if (error.response && error.response.status === 409) {
        // User exists, get token and proceed to home
        const params = new URLSearchParams();
        params.append('username', result.user.email);
        params.append('password', 'firebase-auth');
        
        const tokenResponse = await axios.post(`${process.env.REACT_APP_API_URL}/token`, params);
        const token = tokenResponse.data.access_token;
        const existingUser = error.response.data.detail.user;

        localStorage.setItem('userId', existingUser.id);
        localStorage.setItem('token', token);
        localStorage.setItem('email', existingUser.email);
        localStorage.setItem('displayName', `${existingUser.first_name} ${existingUser.last_name}`);
        localStorage.setItem('firstName', existingUser.first_name);
        localStorage.setItem('lastName', existingUser.last_name);
        localStorage.setItem('role', existingUser.role);
        localStorage.setItem('company', existingUser.company);

        if (onSuccess) {
          await onSuccess(existingUser);
        }
        window.location.href = '/home';
      } else {
        throw error;
      }
    }
  } catch (error) {
    console.error("Error logging in with Google:", error);
    alert("Login failed. Please try again.");
  }
};

const handleEmailLogin = async (navigate, email, password, { onSuccess }) => {
  try {
    const params = new URLSearchParams();
    params.append('username', email);
    params.append('password', password);

    const response = await axios.post(`${process.env.REACT_APP_API_URL}/token`, params);

    const token = response.data.access_token;
    localStorage.setItem('token', token);

    // Decode the JWT to get the user information
    const decodedToken = jwtDecode(token);
    const userId = decodedToken.sub;
    
    // Get user details to store first and last name
    const userResponse = await axios.get(`${process.env.REACT_APP_API_URL}/users/${userId}`);
    const userDetails = userResponse.data;
    
    localStorage.setItem('userId', userId);
    localStorage.setItem('user', email);
    localStorage.setItem('email', email);
    localStorage.setItem('firstName', userDetails.first_name);
    localStorage.setItem('lastName', userDetails.last_name);
    localStorage.setItem('role', userDetails.role);
    localStorage.setItem('company', userDetails.company);
    localStorage.setItem('displayName', `${userDetails.first_name} ${userDetails.last_name}`);
    
    const userWithId = { 
      user: email, 
      displayName: `${userDetails.first_name} ${userDetails.last_name}`, 
      id: userId, 
      email: email,
      firstName: userDetails.first_name,
      lastName: userDetails.last_name,
      role: userDetails.role,
      company: userDetails.company
    };
    await onSuccess(userWithId);
  } catch (error) {
    if (error.response) {
      console.error("Error response:", error.response.data);
      alert(`Login failed: ${error.response.data.detail || "Please try again."}`);
    } else {
      console.error("Error logging in with email and password:", error);
      alert("Login failed. Please try again.");
    }
  } finally {
    navigate('/home');
  }
};

export { handleEmailLogin, handleGoogleLogin, storeUserInDatabase };
